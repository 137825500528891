.color-pie {
  min-width: 3.5rem;
  height: 3.5rem;
  // border-radius: 2rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex: 0 0 3.5rem;

  // box-shadow: 0px 0px 0px 1px gray;
  &__color {
    height: 3.5rem;
    width: 3.5rem;
  }

  &__percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    &.atBottom {
      top: auto;
      bottom: 0.25rem;
      transform: unset;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    }
  }
}

.color-pie__color.no-background {
  background-color: transparent !important;
  border: none !important;
}

.color-pie__color.hide-color-pom {
  img {
    visibility: hidden;
  }
}

img.color-pom-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.color-index {
  flex: 0 0 1.4rem;
  color: var(--text-primary);
  width: 1.4rem;
  overflow: hidden;
  text-align: center;
  margin-top: 0.25rem;

  &.color-pie__colorindex {
    position: absolute;
    width: auto;
  }
}

.color-area-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;

  &:last-child {
    margin-bottom: 1rem;
  }

  &>.area-swatch {
    width: 100%;
  }
}

.area-swatch {
  border-radius: 0.25rem;
  display: inline-flex;
  padding: 0.5rem 0;
  // min-width:12rem;
  color: var(--text-primary);

  &.active {
    background-color: var(--bg-primary-active);
    color: var(--text-primary);
  }
}

.color-details-area {
  align-self: center;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  &>div {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.color-details-name {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.25rem;
  }
}

.color-area-popover {
  &>.bp3-popover-target {
    width: 100%;
  }
}

.at-section__title__controls {
  margin-left: -0.6rem;
}

.at-design-color-options {
  display: inline-flex;
  align-items: center;
  transition: opacity 100ms ease-out;
  flex: 0 0 auto;
  width: calc(100% - 4px);
  border: 2px solid var(--bg-secondary);
  box-sizing: border-box;
  border-radius: 4px;

  &.invisible {
    opacity: 0;
    overflow: hidden;
    width: 0;
  }

  &.collapsed {
    display: none;
  }

  &>* {
    display: flex;
    flex: 1 1;
  }

  &>.colortools-divider {
    display: none;
  }

  &>button.at-button.at-small {
    min-width: 3rem;
  }
}

#at-section-tools-autocolor,
#at-design-color-options-shufflecolors {
  display: none;
}

.at-section-container.collapsed {
  display: none;
}

label.bp3-control.bp3-checkbox.at-carving-checkbox {
  margin: 1rem 0;
  text-transform: uppercase;
}

.at-colorboxTooltipmsg {
  background: #333;
  border: 1px solid #666;
  color: #f5f5f5;
  padding: 4px 6px;
  font-size: 13px;
  text-transform: uppercase;
}

.at-masterCarving-Fade {
  background-color: var(--bg-primary-hover) !important;
  color: var(--text-primary-hover) !important;
}

@media screen and (max-width: 500px) {
  .at-design-color-options {
    display: flex;
    gap: 0.25rem;
    flex-wrap: wrap;
  }
}