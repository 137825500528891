.at-create-combo-area {
  width: 100%;
}
.at-create-combo-options-area label.at-radio {
  display: block;
}

.at-create-combo-options-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
}