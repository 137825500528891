.at-login-screen {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.at-promotionals {
  flex-basis: 0;
  flex-grow: 1;
  background: url(login.jpg);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;

  & > .bp3-card {
    position: absolute;
    bottom: 7rem;
    transform: translateX(-50%);
    left: 50%;
    width: 30rem;
    color: var(--text-primary-active);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: $layer-3-temp-nav;

    & > p {
      margin-top: 1rem;
      text-align: center;
    }

    & > .at-button {
      margin-top: 2rem;
    }
  }
}

.at-login-input .bp3-input {
  box-shadow: inset 0 0 0 2px var(--text-primary-muted);
  background-color: var(--bg-secondary);
}

.login-field {
  flex: 0 0 42%;
  height: auto;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-primary-active);
}

.signing-spinner {
  display: inline-flex;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  vertical-align: bottom;
  opacity: 0;
  transition: opacity 0.4s;
}

.signing-spinner.show {
  opacity: 1;
}

.signin-span-text {
  margin-left: -1rem;
  transition: 0.5s;
}

.signin-span-text.shift-right {
  margin-left: 0;
}

.at-login-screen.separate-loginpage {
  .at-promotionals {
    display: none;
  }
  .login-field {
    flex: auto;
  }
}

.privacy-policy {
  position: absolute;
  z-index: 10;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
  color: var(--text-primary-active);
  font-size: 0.8rem;
  & > a {
    display: block;
    color: var(--text-primary-active);
  }
}
